
:root {
--color-system-black-1: #040404;
--color-system-black-2: #131313;
--color-system-black-3: #202020;
--color-system-gray-1: #EBEBEB;
--color-system-gray-2: #DDDDDD;
--color-system-gray-3: #BFBFBF;
--color-system-gray-4: #A2A1A1;
--color-system-gray-5: #868686;
--color-system-gray-6: #6A6A6A;
--color-system-gray-7: #505050;
--color-system-gray-8: #3F3F3F;
--color-system-gray-9: #2E2E2E;
--color-system-white-1: #FFFFFF;
--color-system-white-2: #F8F8F8;
--color-system-white-3: #F4F4F4;
--color-system-brand-1: #66E2CC;
--color-system-brand-2: #33D9BB;
--color-system-brand-3: #00CFAA;
--color-system-brand-4: #00A688;
--color-system-brand-5: #007C66;
--color-system-danger-1: #F080AD;
--color-system-danger-2: #EB5591;
--color-system-danger-3: #E62B76;
--color-system-danger-4: #B8225E;
--color-system-danger-5: #8A1A47;
--color-system-point-a-1: #FFF0D9;
--color-system-point-a-2: #FFCD92;
--color-system-point-a-3: #FF9D55;
--color-system-point-a-4: #FF842B;
--color-system-point-a-5: #EB6B0F;
--color-system-point-b-1: #66A3FF;
--color-system-point-b-2: #3384FF;
--color-system-point-b-3: #0065FF;
--color-system-point-b-4: #0051CC;
--color-system-point-b-5: #003D99;
--color-blue-night-black-1: #11153A;
--color-blue-night-black-2: #181C4C;
--color-blue-night-black-3: #1B2055;
--color-blue-night-gray-1: #D9DFF5;
--color-blue-night-gray-2: #C7CFE9;
--color-blue-night-gray-3: #919DC5;
--color-blue-night-gray-4: #6C7BAC;
--color-blue-night-gray-5: #59669A;
--color-blue-night-gray-6: #475187;
--color-blue-night-gray-7: #343B74;
--color-blue-night-gray-8: #1F2460;
--color-blue-night-gray-9: #1D225A;
--color-blue-night-white-1: #FFFFFF;
--color-blue-night-white-2: #F3F5FC;
--color-blue-night-white-3: #F3F5FC;
--font-size-h0: 48px;
--font-size-h1: 30px;
--font-size-h2: 26px;
--font-size-h3: 24px;
--font-size-h4: 18px;
--font-size-body1: 16px;
--font-size-body2: 14px;
--font-size-body3: 12px;
--font-size-caption1: 11px;
--font-size-caption2: 10px;
--font-size-caption3: 8px;
--font-weight-black: 900;
--font-weight-bold: 700;
--font-weight-semibold: 500;
--font-weight-regular: 400;
--font-weight-light: 300;
}